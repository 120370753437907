
import { Component, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Notification } from '@/modules/common/components/Notification';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { PasswordModal } from '@/modules/authentication/components/PasswordModal';
import { IconButton, MFATemplate, NavBar } from '@warrenbrasil/nebraska-web';
import { parseISO, format as formatDate } from 'date-fns';
import {
  IMFAStatus,
  IOTPResponse,
  hasMFA,
  enableMFA,
  requestOTPToken
} from '@/modules/authentication/services/mfa';
import {
  LOADING_ERROR,
  MFA_ACTIVATED,
  MFA_ACTIVATION_ERROR,
  SEND_TOKEN_ERROR,
  SEND_TOKEN_SUCCESS,
  SEND_TOKEN_SUCCESS_PUSH
} from './helpers/text-constants/text-constants';

import { MFAViewActivated } from './components/MFAViewActivated';
import { MFAViewActivatedFeedback } from './components/MFAViewActivatedFeedback';
import { MFAViewActivation } from './components/MFAViewActivation';

import { EMethods, MFAModules } from './types';

@Component({
  components: {
    IconButton,
    MFATemplate,
    MFAViewActivated,
    MFAViewActivatedFeedback,
    MFAViewActivation,
    NavBar,
    Notification,
    PasswordModal
  }
})
export default class MultiFactorAuthentication extends Vue {
  private NebraskaColors = NebraskaColors;

  private userMFAActivationDate = '';
  private MFAToken = '';

  private isLoading = true;
  private displayPasswordModal = false;
  private goToNextStep = false;
  private hasError = false;
  private isMFAActive = false;
  private showMFAViewActivatedFeedback = false;
  private method = EMethods.SMS;

  get cameFromTrade() {
    return this.$route.query?.module === MFAModules.TRADE;
  }

  get cameFromMenuChangeEmail() {
    return this.$route.query?.module === MFAModules.MENU_CHANGE_EMAIL;
  }

  get cameFromMenuEditRegister() {
    return this.$route.query?.module === MFAModules.MENU_EDIT_REGISTER;
  }

  private async mounted() {
    this.getCustomer();
  }

  private async getCustomer() {
    try {
      const response: IMFAStatus = await hasMFA();
      this.handleCustomer(response);
    } catch {
      this.notificationToast(LOADING_ERROR, NotificationTypes.error);
    } finally {
      this.setLoading(false);
    }
  }

  private handleCustomer(response: IMFAStatus) {
    if (this.canEnableMFA(response)) {
      this.setMFAActive(response);
      if (this.isEnableMFA(response)) {
        this.setUserMFAActivationDate(response);
      }
    }
  }

  private canEnableMFA(response: IMFAStatus) {
    return !!response.canEnable;
  }

  private setMFAActive(response: IMFAStatus) {
    if (this.cameFromTrade) {
      this.isMFAActive = response.enabled && response.hasValidOtp;
    } else {
      this.isMFAActive = response.enabled;
    }
  }

  private isEnableMFA(response: IMFAStatus) {
    return response.enabledAt;
  }

  private setUserMFAActivationDate(response: IMFAStatus) {
    this.userMFAActivationDate = formatDate(
      parseISO(String(response.enabledAt)),
      'dd/MM/yyyy'
    );
  }

  private handleSubmit(mfaToken: string) {
    this.MFAToken = mfaToken;
    if (this.isMFAActive) return;

    this.setShowPasswordModal(true);
  }

  private async setUserPasswordAndToggleMFA(userPassword: string) {
    try {
      this.setLoading(true);
      await enableMFA({
        authToken: this.MFAToken,
        password: userPassword
      });
      this.setShowMFAViewActivatedFeedback(true);
      if (this.cameFromMenuChangeEmail) {
        this.$router.push({
          name: MFAModules.MENU_CHANGE_EMAIL
        });
      }
      if (this.cameFromMenuEditRegister) {
        this.$router.push({
          name: MFAModules.MENU_EDIT_REGISTER
        });
      }
      this.notificationToast(MFA_ACTIVATED, NotificationTypes.success);
    } catch {
      this.setError(true);
      this.notificationToast(MFA_ACTIVATION_ERROR, NotificationTypes.error);
    } finally {
      this.setShowPasswordModal(false);
      this.setLoading(false);
    }
  }

  private async sendOTPToken() {
    try {
      const response: IOTPResponse = await requestOTPToken(this.method);
      const recipientLastNumbers = response.recipient;
      const notificationText =
        this.method === EMethods.SMS
          ? SEND_TOKEN_SUCCESS + recipientLastNumbers
          : SEND_TOKEN_SUCCESS_PUSH;
      this.notificationToast(notificationText, NotificationTypes.success);
    } catch {
      this.notificationToast(SEND_TOKEN_ERROR, NotificationTypes.error);
    } finally {
      this.method = EMethods.SMS;
    }
  }

  private onBackClick() {
    this.$router.back();
  }

  private onCloseModal() {
    this.setShowPasswordModal(false);
  }

  private setShowPasswordModal(isOpen: boolean) {
    this.displayPasswordModal = isOpen;
  }

  private setShowMFAViewActivatedFeedback(isVisible: boolean) {
    this.showMFAViewActivatedFeedback = isVisible;
  }

  private setError(hasError: boolean) {
    this.hasError = hasError;
  }

  private setNextStep() {
    this.method = EMethods.PUSH;
    this.sendOTPToken();

    this.goToNextStep = true;
  }

  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  private notificationToast(message: string, type: NotificationTypes) {
    this.$notify({
      group: 'wrn-notifications',
      text: message,
      type
    });
  }
}
