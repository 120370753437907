export interface IHero {
  totalBalance: string;
  totalBalanceUpdatedAt: string;
  accountBalance: string;
  buttons: IButton[];
}

export interface IButton {
  action: string;
  icon: string;
  isActive: boolean;
  label: string;
}

export interface ISummary {
  products: IProduct[];
  services: IProductService[];
}

export interface IProduct {
  type: EProductViewCardType;
  icon: string;
  color: { token: string; hexa: string };
  title: string;
  productType: EProductType;
  amount: { value: number; formatted: string } | null;
  description: string | null;
  infos: {
    label: string;
    value: string;
  }[];
  details: IProductDetails | null;
}

export enum EProductViewCardType {
  Product = 'product',
  Brand = 'brand'
}

export enum EProductType {
  Funds = 'funds',
  GovernmentBonds = 'government-bond',
  Pension = 'private-pension',
  FixedIncome = 'fixed-income'
}

export interface IProductDetails {
  title: string;
  period: string;
  data: IProductDetailsInfo[];
}

export interface IProductDetailsInfo {
  label: string;
  rentability: string;
  value: string;
}

export interface IProductService {
  label: string;
  icon: string;
  url: string;
}
