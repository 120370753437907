import { WithdrawStep } from '../components/WithdrawStep';
import { ReviewStep } from '../components/ReviewStep';
import { WithdrawCompletedStep } from '../components/WithdrawCompletedStep';

export enum EProductWithdrawSteps {
  WITHDRAW,
  REVIEW,
  CONCLUSION
}

export const ProductWithdrawComponents = {
  [EProductWithdrawSteps.WITHDRAW]: WithdrawStep,
  [EProductWithdrawSteps.REVIEW]: ReviewStep,
  [EProductWithdrawSteps.CONCLUSION]: WithdrawCompletedStep
};
