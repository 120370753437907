import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IOrderFiltersResponse,
  IOrderListResponse,
  IOrderListRequest,
  IOrderReceipt,
  IOrdersListRequestFilters,
  IOrdersListResponse
} from './types';
import { getCustomerId } from '@/modules/common/services/local-storage';

export const httpInvestments = httpClient.withOptions({
  baseURL: process.env.VUE_APP_BFF_INVESTMENTS_URL
});

export const httpPortfolios = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/portfolios/`
});

export function getOrderFilters(): Promise<IOrderFiltersResponse> {
  const params = { customerId: getCustomerId() };
  return httpInvestments.get(`/v1/order/filters`, { params });
}

export function getOrderList(
  filters: IOrderListRequest
): Promise<IOrderListResponse> {
  const formattedFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== '')
  );
  const params = {
    customerId: getCustomerId(),
    ...formattedFilters
  };
  return httpInvestments.get(`/v1/order/list`, { params });
}

export function getOrdersList(
  portfolioId: string,
  filters: IOrdersListRequestFilters
): Promise<IOrdersListResponse> {
  const formattedFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== '')
  );
  const params = {
    ...formattedFilters
  };
  return httpPortfolios.get(`v1/orders/list/${portfolioId}`, { params });
}

export function getOrderReceiptDetails(
  OrderID: string
): Promise<IOrderReceipt> {
  return httpInvestments.get(`/v2/orders/${OrderID}/receipt`);
}

export function cancelOrder(OrderID: string): Promise<void> {
  return httpInvestments.put(`/v1/orders/${OrderID}/cancel`);
}
