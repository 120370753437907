
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';

import { PortfoliosToInvestCardLoader } from '../PortfoliosToInvestCardLoader';

import { InvestmentsModule } from '@/modules/wealth/store/investments';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { Notification } from '@/modules/common/components/Notification';
import { WarrenModules } from '@/types/navigation';
import { trackHomeByAction } from '@/modules/home/helpers/tracking';
import { IGetTransactionOptions } from '@/modules/wealth/services/withdraw';
import { PortfolioCard } from '@/modules/wealth/views/Wealth/components/PortfolioCard';
import { GoalType } from '@/types/models/Portfolio';

import { IPortfolio } from '@/modules/wealth/services/investment';
import { WarrenSession } from '@/modules/common/services/session';

import {
  userCanPerform,
  Operation,
  isImpersonate
} from '@/modules/common/services/partner';

@Component({
  name: 'PortfoliosToInvestPortfolioList',
  components: {
    PortfoliosToInvestCardLoader,
    Notification,
    PortfolioCard
  }
})
export default class PortfoliosToInvestPortfolioList extends Vue {
  @Prop({ type: Array, required: true })
  private portfolios!: IPortfolio[];

  @Prop({ type: Boolean, required: true })
  private isLoading!: boolean;

  public isFetchingInvestmentTypes = false;
  private typeTransactions?: IGetTransactionOptions | null = null;

  @Emit('tracking')
  private trackPortfolioClick(portfolioName: string) {
    return portfolioName;
  }

  private get typeInvestments() {
    return this.typeTransactions ? this.typeTransactions.investment : [];
  }

  private async getTypeInvestments(portfolio: IPortfolio) {
    await InvestmentsModule.getPortfolioTransactionOptions({
      id: portfolio.id,
      customer: WarrenSession.get('warren.customerId')
    });
    this.typeTransactions =
      InvestmentsModule.transactionType as IGetTransactionOptions;
  }

  private redirectValidation(portfolio: IPortfolio) {
    const errorText = InvestmentsModule.errorTypeTransactions
      ? 'Algo deu errado! Aguarde alguns instantes e tente novamente.'
      : 'Não é possível realizar investimento nessa carteira!';

    if (
      this.typeInvestments.length === 0 ||
      InvestmentsModule.errorTypeTransactions
    ) {
      this.$notify({
        group: 'portfolios-to-investment-portfolio-list',
        text: errorText,
        type: NotificationTypes.error,
        duration: 5000
      });
    } else {
      this.redirectToInvestment(portfolio);
    }
  }

  private redirectToInvestment(portfolio: IPortfolio) {
    this.$router.push({
      name: 'investmentTypeSelection',
      params: { id: portfolio.id }
    });
  }

  public async portfolioClick(portfolio: IPortfolio) {
    if (this.isPortfolioDisabled(portfolio)) return;
    const { cameFrom } = this.$route.query;
    if (cameFrom === WarrenModules.Home)
      trackHomeByAction('Investir \\ Carteira existente');
    this.trackPortfolioClick(portfolio.name);

    this.isFetchingInvestmentTypes = true;
    await this.getTypeInvestments(portfolio);
    this.redirectValidation(portfolio);
    this.isFetchingInvestmentTypes = false;
  }

  public isPortfolioDisabled(portfolio: IPortfolio) {
    if (!isImpersonate()) return !portfolio.canInvestInPortfolio;

    if (!portfolio.isCustom) return !portfolio.canInvestInPortfolio;

    if (portfolio.goal === GoalType.PRIVATE_PENSION)
      return !portfolio.canInvestInPortfolio;

    return (
      !userCanPerform(Operation.DepositByProduct) ||
      !portfolio.canInvestInPortfolio
    );
  }
}
