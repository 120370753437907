var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"about-links",attrs:{"data-testid":"about-links"}},[_c(_setup.Heading,{staticClass:"about-links__title",attrs:{"level":2}},[_vm._v("Configurações")]),(!_setup.isPortfolioDistributionLoading)?_c('div',[_c(_setup.Row,{class:{
        'about-links__disabledRow': !_setup.isAllowedToEditPortfolio
      },attrs:{"left-title":"Alterar imagem da capa","has-line":false,"chevron":"","has-interaction":_setup.isAllowedToEditPortfolio},on:{"click":function($event){_setup.isAllowedToEditPortfolio && _setup.emit('openChangeBackground')}},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c(_setup.InitialAvatarIcon,{attrs:{"icon":"G0020","size":"small"}})]},proxy:true}],null,false,3955583551)}),_c(_setup.Row,{class:{
        'about-links__disabledRow':
          _setup.isPrivatePension || !_setup.isAllowedToEditPortfolio
      },attrs:{"left-title":"Alterar nome da carteira","left-subtitle":_setup.portfolioName,"chevron":"","has-interaction":!_setup.isPrivatePension && _setup.isAllowedToEditPortfolio},on:{"click":function($event){!_setup.isPrivatePension && _setup.isAllowedToEditPortfolio && _setup.emit('openRename')}},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c(_setup.InitialAvatarIcon,{attrs:{"icon":"EE0070","size":"small"}})]},proxy:true}],null,false,3512136061)}),(_setup.showEditProducts)?_c(_setup.Row,{attrs:{"left-title":"Editar produtos","left-subtitle":"Adicionar ou remover produtos","chevron":"","has-interaction":""},on:{"click":_setup.routeToWalletBuilderEdit},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c(_setup.InitialAvatarIcon,{attrs:{"icon":"EF0110","size":"small"}})]},proxy:true}],null,false,1931050937)}):_vm._e(),_c(_setup.Row,{class:{
        'about-links__disabledRow': !_setup.isAllowedToDeletePortfolio
      },attrs:{"left-title":"Excluir carteira","left-subtitle":!_setup.isAllowedToDeletePortfolio
          ? 'Esta Carteira não pode ser excluída'
          : '',"chevron":"","has-interaction":_setup.isAllowedToDeletePortfolio},on:{"click":function($event){_setup.isAllowedToDeletePortfolio && _setup.emit('openDelete')}},scopedSlots:_vm._u([{key:"icon-left",fn:function(){return [_c(_setup.InitialAvatarIcon,{attrs:{"icon":"EE0010","size":"small"}})]},proxy:true}],null,false,3592002939)})],1):_c(_setup.SkeletonLoader,_vm._l((3),function(index){return _c('div',{key:index,staticClass:"about-links__loader"})}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }