import { IHero, ISummary } from './types';
import { httpClient } from '@/modules/common/services/http/http-client';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/products`
});

export function getHero(): Promise<IHero> {
  return http.get(`v1/shelf/hero`);
}

export function getSummary(): Promise<ISummary> {
  return http.get(`v1/shelf/summary`);
}
