import { render, staticRenderFns } from "./ProductToInvestRow.vue?vue&type=template&id=3ab13259&scoped=true&"
import script from "./ProductToInvestRow.vue?vue&type=script&lang=ts&"
export * from "./ProductToInvestRow.vue?vue&type=script&lang=ts&"
import style0 from "./ProductToInvestRow.vue?vue&type=style&index=0&id=3ab13259&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab13259",
  null
  
)

export default component.exports