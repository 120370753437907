import { render, staticRenderFns } from "./InputMoney.vue?vue&type=template&id=b3f62fb2&scoped=true&"
import script from "./InputMoney.vue?vue&type=script&setup=true&lang=ts&"
export * from "./InputMoney.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./InputMoney.vue?vue&type=style&index=0&id=b3f62fb2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3f62fb2",
  null
  
)

export default component.exports