import { httpClient } from '@/modules/common/services/http/http-client';
import { IMyAccountMenu } from '../../types';

const baseURL = `${process.env.VUE_APP_BFF_ACCOUNT_URL}`;
export const httpClientAccountBFF = httpClient.withOptions({ baseURL });

export function getMyAccountMenu(customerId: string): Promise<IMyAccountMenu> {
  return httpClientAccountBFF.get(
    `/v2/customer/my-account-menu?customerId=${customerId}`
  );
}
