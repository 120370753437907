import { httpClient } from '@/modules/common/services/http/http-client';
import {
  IWithdrawDetails,
  IWithdrawAmount,
  IWithdrawReview,
  IWithdrawConclusion
} from './product-withdraw-types';

export const http = httpClient.withOptions({
  baseURL: `${process.env.VUE_APP_BFF_URL}/instruments`
});

export function getWithdrawDetails(
  productId: string
): Promise<IWithdrawDetails> {
  return http.get(`/v1/withdrawals/instrument/${productId}/info`);
}

export function getWithdrawAmount(
  productId: string,
  amount: number
): Promise<IWithdrawAmount> {
  return http.post(`/v1/withdrawals/instrument/amount`, {
    productId,
    amount
  });
}

export function getWithdrawReview(
  productId: string,
  amount: number
): Promise<IWithdrawReview> {
  return http.post(`/v1/withdrawals/instrument/preview`, {
    productId,
    amount
  });
}

export function makeWithdraw(
  productId: string,
  amount: number
): Promise<IWithdrawConclusion> {
  return http.post(`/v1/withdrawals/instrument`, {
    productId,
    amount
  });
}
