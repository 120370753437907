import { render, staticRenderFns } from "./ProductsViewHero.vue?vue&type=template&id=2de3a5c2&scoped=true&"
import script from "./ProductsViewHero.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProductsViewHero.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProductsViewHero.vue?vue&type=style&index=0&id=2de3a5c2&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de3a5c2",
  null
  
)

export default component.exports