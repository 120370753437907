
import { namespace } from 'vuex-class';

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { RequestStatus } from '@/modules/trade/store/trade';

import { HelpButton } from '@/modules/trade/components/HelpButton';
import { FooterButton } from './components/FooterButton';
import { AccountStatus } from '@/modules/trade/services/account';

const tradePositionsModule = namespace('tradeModule');

@Component({
  components: {
    HelpButton,
    FooterButton
  }
})
export default class GeneralFooter extends Vue {
  readonly RequestStatus = RequestStatus;

  @Prop({ type: String, required: true })
  readonly tradeMapTitle!: string;

  @Prop({ type: Object, required: false })
  readonly tradeStatus?: AccountStatus;

  @tradePositionsModule.State('accountStatusRequestStatus')
  readonly accountStatusRequestStatus!: RequestStatus;

  @Emit('request-trade-map')
  public requestTradeMapAction() {}

  @Emit('track-account')
  public trackAccountStatements() {}

  @Emit('help-click')
  public emitHelpClick() {}

  get isRLPEnabled() {
    return !!this.tradeStatus?.isRLPEnabled;
  }

  get hasFailedAccountStatusRequest() {
    return this.accountStatusRequestStatus === RequestStatus.Error;
  }

  get enabledMFA() {
    return this.tradeStatus?.enabledMFA;
  }

  get enabledMFATradeMapButton() {
    return this.hasFailedAccountStatusRequest || this.enabledMFA;
  }

  get computedTradeMapSubText() {
    return this.hasFailedAccountStatusRequest
      ? 'Serviço indisponível no momento'
      : '';
  }
}
