import { NavigationGuard } from 'vue-router';
import { getCustomTheme, StorageKeys } from '../../services/local-storage';
import { CustomerModule } from '../../store/CustomerModule';
import { WarrenSession } from '../../services/session';

export const redirectToOnboardingSignIn: NavigationGuard = async (
  _to,
  _from,
  next
) => {
  logOffUser();
  const env = process.env.NODE_ENV as 'staging' | 'production' | 'development';

  if (env !== 'development') {
    const theme = getCustomTheme();

    const domain = theme?.brandDomain
      ? theme.brandDomain.toLowerCase()
      : 'warren';

    const platform = window.innerWidth <= 768 ? 'mobile' : 'desktop';

    const redirectUrls = {
      staging: `https://stg.warren.com.br/onboarding/${domain}/${platform}`,
      production: `https://warren.com.br/onboarding/${domain}/${platform}`
    };

    const hash = window.location.hash;
    const queryIndex = hash.indexOf('?');
    const currentQueryParams =
      queryIndex !== -1
        ? new URLSearchParams(hash.substring(queryIndex))
        : new URLSearchParams();

    const redirectUrl = new URL(redirectUrls[env]);
    currentQueryParams.forEach((value, key) => {
      redirectUrl.searchParams.append(key, value);
    });

    window.location.href = redirectUrl.toString();
  }

  next();
};
function logOffUser() {
  CustomerModule.resetCustomer();
  CustomerModule.resetAccountStatus();
  const keysToPreserve = [
    StorageKeys.Theme,
    StorageKeys.DarkMode,
    StorageKeys.LastPartnerDashRoute,
    StorageKeys.PartnerShowMoney,
    StorageKeys.PartnerDashNewsInformation,
    StorageKeys.PartnerProfileNewsInformation,
    StorageKeys.PartnerStatementNewsInformation,
    StorageKeys.PartnerPortfolioNewsInformation,
    StorageKeys.NewPartnerFeaturesViewed,
    StorageKeys.ShowedPortfoliosTemplateTutorial,
    StorageKeys.HasDownloadedFeeStatement,
    StorageKeys.HasUsedPositionDetailsButton
  ];

  WarrenSession.clean(keysToPreserve);
}
