
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Row, BaseText, InputMoney } from '@warrenbrasil/nebraska-web';
import { MatchMedia } from '@/modules/common/components/MatchMedia';
import { getInvestmentAmount } from '@/modules/transaction/services/product-investment';

@Component({
  components: { Row, BaseText, MatchMedia, InputMoney }
})
export default class ProductToInvestRow extends Vue {
  @Prop({ type: String, required: false })
  readonly errorMessage?: string;

  @Prop({ type: Boolean, required: false, default: true })
  readonly isAvailable?: boolean;

  @Prop({ type: Object, required: true })
  readonly minToInvest!: { value: number; formatted: string };

  @Prop({ type: String, required: true })
  readonly productName!: string;

  @Prop({ type: String, required: true })
  readonly productId!: string;

  public valueToInvest = 0;

  public get inputValue() {
    return this.valueToInvest > 0 ? this.valueToInvest : undefined;
  }

  handleInput(value: number) {
    this.updateValue(value);
  }

  @Emit()
  updateValue(value: number, fetchingAmount = true) {
    this.valueToInvest = value;

    return { id: this.productId, value: this.valueToInvest, fetchingAmount };
  }

  handleBlur() {
    if (this.valueToInvest >= this.minToInvest.value) {
      this.fetchInvestmentAmount(this.valueToInvest);
    }
  }

  async fetchInvestmentAmount(value: number) {
    try {
      const data = await getInvestmentAmount(this.productId, value);

      this.valueToInvest = data.amount.value;
      this.updateValue(data.amount.value, false);
    } catch {
      this.updateValue(0, false);
    }
  }
}
