
import { Component, Vue } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import { InvestmentTransactionNavbar } from '@/modules/transaction/views/InvestmentTransaction/components/InvestmentTransactionNavbar';
import {
  Heading,
  BaseText,
  ActionableHelperView
} from '@warrenbrasil/nebraska-web';
import { AccountBalance } from '@/modules/transaction/views/InvestmentTransaction/components/AccountBalance';
import { ProductToInvestRow } from './components/ProductToInvestRow';
import { ProductsToInvestHeader } from './components/ProductsToInvestHeader';
import {
  getPortfolioCatalog,
  getAccountBalance,
  submitPortfolioInvestmentByProduct,
  IGetAccountBalance,
  IPortfolioCatalog,
  IPortfolioInvestmentSuccessRequest
} from '@/modules/wealth/services/investment';

import { ProductsTableSkeleton } from './components/ProductsTableSkeleton';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { Footer } from './components/Footer';
import { InvestmentsModule } from '@/modules/wealth/store/investments';
import { namespace } from 'vuex-class';
import { NotificationTypes } from '@/modules/common/services/http/notification';
import { Notification } from '@/modules/common/components/Notification';
import { RiskValidation } from '@/modules/common/components/RiskValidation';
import { IInvestmentProfile } from '@/types/models';
import { getCustomerInvestmentProfile } from '@/modules/common/services/http/account';
import { MatchMedia } from '@/modules/common/components/MatchMedia';
import { moneyViewer } from '@/modules/common/helpers/currency';
import {
  userCanPerform,
  Operation,
  isImpersonate,
  getPartner
} from '@/modules/common/services/partner';
import { ECameFromPath } from '@/modules/transaction/views/InvestmentTransaction/types';
import * as trackProductTransaction from './track-events';
import ReviewTransactionDetails from '@/modules/transaction/views/InvestmentTransaction/components/WealthTransaction/components/ReviewTransactionDetails/ReviewTransactionDetails.vue';
import { getPortfolioReview } from '@/modules/wealth/services/portfolio';
import {
  ManualPortfolioReviewProducts,
  PortfolioReviewData,
  PortfolioReviewDirection
} from '@/modules/wealth/services/portfolio/portfolio-types';
import { GoalType } from '@/types/models/Portfolio';

const customerModule = namespace('CustomerModule');

@Component({
  components: {
    InvestmentTransactionNavbar,
    Heading,
    AccountBalance,
    ProductToInvestRow,
    ProductsToInvestHeader,
    ProductsTableSkeleton,
    BaseText,
    ActionableHelperView,
    Notification,
    Footer,
    RiskValidation,
    MatchMedia,
    ReviewTransactionDetails
  }
})
export default class WealthProductTransaction extends Vue {
  @customerModule.Getter('getCustomerId')
  private customerId?: string;

  private accountBalance: IGetAccountBalance | null = null;

  public termsProductIds: string[] = [];

  public investmentProfile?: IInvestmentProfile = {};

  public errorOnPageLoadRequests = false;
  public isLoading = true;
  public isLoadingSubmit = false;
  public isLoadingRefreshBalance = false;
  public submitError = {
    disableButton: false,
    message: ''
  };

  public isFetchingAmount = false;

  public showReviewModal = false;
  public portfolioReviewData: PortfolioReviewData | null = null;

  public portfolioName = '';
  public products: IPortfolioCatalog[] = [];
  private NebraskaColors = NebraskaColors;
  private showRiskValidation = false;
  private portfolioGoal = '';

  private goBack() {
    if (this.errorOnPageLoadRequests) {
      trackProductTransaction.trackCancelButton();
    } else {
      trackProductTransaction.trackBackEvent();
    }
    this.$router.push({ name: 'wealthPortfolio' });
  }

  get portfolioId() {
    return this.$route.params.id || '';
  }

  private redirectToTransaction() {
    this.$router.push({
      name: 'investment-transaction',
      params: { portfolio: this.portfolioId },
      query: { from: ECameFromPath.WealthPortfolio }
    });
  }

  get canDepositByProduct() {
    if (!isImpersonate()) {
      return true;
    }

    if (this.portfolioGoal === GoalType.PRIVATE_PENSION) return true;

    return userCanPerform(Operation.DepositByProduct);
  }

  get canShowTermsAndRisks() {
    return userCanPerform(Operation.ShowTermsAndRisks);
  }

  created() {
    this.getInfo();
  }

  async getInfo(isTraking = false) {
    if (isTraking) {
      trackProductTransaction.trackTryAgainButton();
    }
    this.isLoading = true;
    this.errorOnPageLoadRequests = false;
    await this.getProductsCatalog();
    await this.getAccountBalance();

    if (!this.canDepositByProduct) {
      this.redirectToTransaction();
    }

    this.setInvestorProfile();
    this.isLoading = false;
  }

  async setInvestorProfile() {
    if (this.customerId) {
      try {
        const { investmentProfile } = await getCustomerInvestmentProfile(
          this.customerId
        );
        this.investmentProfile = investmentProfile;
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  }

  async getProductsCatalog() {
    try {
      const portfolioCatalog = await getPortfolioCatalog(
        this.portfolioId,
        this.customerId!
      );
      this.portfolioName = portfolioCatalog.portfolio.name;
      this.products = portfolioCatalog.catalog.products;
      this.sortProductsByAvailability();
      this.portfolioGoal = portfolioCatalog.portfolio.goal;
      !portfolioCatalog.portfolio.isCustom && this.redirectToTransaction();
    } catch (errorOnPageLoadRequests) {
      this.errorOnPageLoadRequests = true;
    }
  }

  async getAccountBalance() {
    try {
      const customerId = this.customerId || '';
      this.accountBalance = await getAccountBalance(customerId);
    } catch (errorOnPageLoadRequests) {
      this.errorOnPageLoadRequests = true;
    }
  }

  async refreshBalance() {
    this.isLoadingRefreshBalance = true;
    await this.getAccountBalance();
    this.isLoadingRefreshBalance = false;
  }

  get accountBalanceFormatted() {
    return this.accountBalance?.summary.totalBalance.formatted || 'R$ 0,00';
  }

  updateProductValue(updateProduct: {
    id: string;
    value: number;
    fetchingAmount: boolean;
  }) {
    this.isFetchingAmount = updateProduct.fetchingAmount;
    this.products = this.products.map(product => {
      if (product.productApiId === updateProduct.id) {
        const available = this.getProductErrors(product, updateProduct.value);
        return {
          ...product,
          valueToInvest: updateProduct.value,
          available
        };
      }
      return product;
    });
  }

  getProductErrors(product: IPortfolioCatalog, newValue: number) {
    let available = { value: true, formatted: '' };

    if (newValue === 0) {
      this.submitError = { disableButton: false, message: '' };
      return available;
    }

    available = this.validateMinimumAmount(product, newValue);

    this.submitError = available.formatted
      ? { disableButton: true, message: '' }
      : { disableButton: false, message: '' };
    return available;
  }

  private validateMinimumAmount(product: IPortfolioCatalog, newValue: number) {
    let available = { value: true, formatted: '' };
    if (product.minimumAmount) {
      available =
        product.minimumAmount.value > newValue
          ? {
              value: true,
              formatted:
                'Escolha um valor maior que o mínimo de movimentação do produto'
            }
          : available;
    }
    return available;
  }

  get totalAmountInvested() {
    return this.products.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.valueToInvest || 0);
    }, 0);
  }

  sortProductsByAvailability() {
    this.products.sort(
      (a, b) =>
        (b.available.value as unknown as number) -
        (a.available.value as unknown as number)
    );
  }

  public onFinishRiskValidation() {
    this.showRiskValidation = false;
    this.submitInvestment();
  }

  public onRiskValidationAbort() {
    this.showRiskValidation = false;
    this.isLoadingSubmit = false;
  }

  get investmentSubmitPayload() {
    const payload: IPortfolioInvestmentSuccessRequest = {
      requestApiId: uuidv4(),
      portfolioApiId: this.portfolioId,
      customerApiId: this.customerId!,
      products: this.products
        .filter(p => p.valueToInvest)!
        .map(p => ({ productApiId: p.productApiId, amount: p.valueToInvest! }))
    };

    if (isImpersonate()) {
      payload.partnerApiId = this.getPartnerId;
    }

    return payload;
  }

  get getPartnerId() {
    return getPartner()?._id || '';
  }

  get isButtonDisabled() {
    if (!this.products.length) return true;
    return (
      this.submitError.disableButton ||
      this.hasAnyErrorInProductsList ||
      this.isFetchingAmount
    );
  }

  get hasAnyErrorInProductsList() {
    const filteredProducts = this.products.filter(
      p =>
        p.available.formatted !==
        'Este investimento será agendado para o próximo dia e horário disponíveis.'
    );

    const hasProductAvailable = !!this.products.filter(p => p.available.value)
      .length;
    const hasAnyErrorInAvailableProducts = (p: IPortfolioCatalog) =>
      p.available.value && p.available.formatted;
    const hasAnyProductError = hasProductAvailable
      ? filteredProducts.some(hasAnyErrorInAvailableProducts)
      : true;
    return hasAnyProductError;
  }

  public async setRisksAndTermsModal() {
    trackProductTransaction.trackAmplitudeInvestmentButton(
      moneyViewer(this.totalAmountInvested)
    );

    if (!(await this.validateSubmitInvestment())) return;

    this.termsProductIds = this.investmentSubmitPayload.products.map(
      p => p.productApiId
    );

    if (this.canShowTermsAndRisks) {
      this.showRiskValidation = true;
      this.isLoadingSubmit = true;
    } else {
      this.submitInvestment();
    }
  }

  public async submitInvestment() {
    this.isLoadingSubmit = true;

    if (!this.validateSubmitInvestment()) return;

    try {
      const payload = this.investmentSubmitPayload;
      await submitPortfolioInvestmentByProduct(payload);

      this.finishInvestment(payload);
    } catch (err) {
      trackProductTransaction.trackEventError();
      this.submitError = {
        disableButton: true,
        message:
          'Erro ao realizar investimento, aguarde alguns instantes e tente novamente'
      };
    } finally {
      this.isLoadingSubmit = false;
      this.showReviewModal = false;
    }
  }

  private finishInvestment(payload: IPortfolioInvestmentSuccessRequest) {
    const getProductName = (id: string) =>
      this.products.find(p => p.productApiId === id)?.name!;
    InvestmentsModule.saveInvestmentCompletedSummary({
      portfolioName: this.portfolioName,
      portfolioApiId: this.portfolioId,
      products: payload.products.map(p => ({
        name: getProductName(p.productApiId),
        amount: {
          value: p.amount,
          formatted: moneyViewer(p.amount, true)
        }
      })),
      total: {
        value: this.totalAmountInvested,
        formatted: moneyViewer(this.totalAmountInvested)
      }
    });
    trackProductTransaction.trackEventSuccess();

    this.$router.push({ name: 'wealthProductTransactionCompleted' });
  }

  public async validateSubmitInvestment() {
    if (
      this.accountBalance?.summary.totalBalance.value! <
      this.totalAmountInvested
    ) {
      this.submitError = {
        disableButton: true,
        message:
          'Você não possui saldo suficiente em conta para investir esse valor'
      };
      this.$notify({
        group: 'investment-by-product-creation',
        text: 'Saldo insuficiente para realizar o investimento',
        type: NotificationTypes.error,
        duration: 5000
      });
      trackProductTransaction.trackHasNoBalance();
      return false;
    } else if (this.products.filter(p => p.valueToInvest).length === 0) {
      this.submitError = {
        disableButton: true,
        message: 'Você precisa investir em pelo menos um produto da carteira'
      };
      return false;
    } else if (this.showReviewModal === false) {
      await this.fetchPortfolioReview();
      return false;
    }

    return true;
  }

  public async fetchPortfolioReview() {
    this.errorOnPageLoadRequests = false;
    this.isLoading = true;
    try {
      const portfolioReviewBody: ManualPortfolioReviewProducts[] = this.products
        .map(p => ({
          productApiId: p.productApiId,
          amountToInvest: p.valueToInvest!
        }))
        .filter(p => p.amountToInvest > 0);

      this.portfolioReviewData = await getPortfolioReview({
        portfolioId: this.portfolioId,
        amount: this.totalAmountInvested,
        direction: PortfolioReviewDirection.Purchase,
        body: portfolioReviewBody
      });

      this.showReviewModal = true;
    } catch (error) {
      this.errorOnPageLoadRequests = true;
    } finally {
      this.isLoading = false;
    }
  }
}
