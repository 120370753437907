
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { ErrorHandler } from '@/modules/common/components/ErrorHandler';
import { FadeTransition } from '@/modules/common/components/FadeTransition';
import HomeCashActions from './components/HomeCashActions.vue';
import HomeCashSummary from './components/HomeCashSummary.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  trackReloadBalanceAccountErrorClick,
  trackBalanceAccountErrorLoad
} from './track-events';
import { trackTransactionsPageNavigation } from '@/modules/transaction/helpers/transactions-events';
import { WarrenModules } from '@/types/navigation';
import * as trackEvents from './track-events';

import {
  getCashSummary,
  CashSummaryResponse
} from '@/modules/home/services/bff';

@Component({
  components: {
    FadeTransition,
    HomeCashActions,
    ErrorHandler,
    HomeCashSummary
  }
})
export default class HomeCash extends Vue {
  readonly NebraskaColors = NebraskaColors;

  @Prop({ type: Boolean, required: true })
  readonly isOverdue!: boolean;

  @Prop({ type: Boolean, required: false })
  public retry!: boolean;

  newCashData: Reactive<CashSummaryResponse> = null;
  isLoading = false;
  hasError = false;
  showBalanceDetail = false;

  created() {
    this.fetchNewCash();
  }

  @Watch('retry', { deep: true })
  watchReloadCash() {
    this.fetchNewCash();
    trackReloadBalanceAccountErrorClick();
  }

  async fetchNewCash() {
    this.isLoading = true;
    this.hasError = false;

    try {
      this.newCashData = await getCashSummary();
    } catch {
      this.hasError = true;
      this.$emit('on-error', this.hasError);
      trackBalanceAccountErrorLoad();
    } finally {
      this.isLoading = false;
    }
  }

  get balance() {
    return this.cashSectionSummary.accountAmount;
  }

  get cashSectionSummary() {
    return {
      netBalance: this.getValueWithDefault(
        this.newCashData?.cashSection.summary?.netBalance?.formatted,
        ''
      ),
      accountAmount: this.getValueWithDefault(
        this.newCashData?.cashSection.summary?.accountAmount?.formatted,
        ''
      )
    };
  }

  getValueWithDefault(value?: string | null, defaultValue = 'R$ 0,00') {
    if (value) return value;
    return defaultValue;
  }

  get hasPositiveBalance() {
    return (
      this.newCashData?.cashSection.summary?.netBalance?.value &&
      this.newCashData.cashSection.summary.netBalance.value > 0
    );
  }

  get hasActiveAccount(): boolean {
    return Boolean(
      this.newCashData?.cashSection.status?.hasInvestmentAccountEnabled
    );
  }

  get processingWarningText() {
    return this.newCashData?.cashSection.status?.processingWarning;
  }

  get cashAccountId() {
    return this.newCashData?.cashSection.status?.investmentAccountId ?? '';
  }

  public redirectToTransactionsPage() {
    this.$router.push({ name: 'transactions' });

    trackTransactionsPageNavigation(WarrenModules.Home);
    trackEvents.trackViewMoreTransactionsEvent();
  }
}
