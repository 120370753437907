import { RouteConfig } from 'vue-router';

import SignInView from '@/modules/authentication/views/SignIn/SignInView.vue';
import SignOutView from '@/modules/authentication/views/SignOut/SignOutView.vue';
import PasswordResetView from '@/modules/authentication/views/PasswordReset/PasswordResetView.vue';
import ResetView from '@/modules/authentication/views/Reset/ResetView.vue';
import SecurityView from '@/modules/authentication/views/SecurityView/SecurityView.vue';
import EmailVerifiedView from '@/modules/authentication/views/EmailVerified/EmailVerifiedView.vue';
import PasswordSetupView from '@/modules/authentication/views/PasswordSetup/PasswordSetupView.vue';
import HBMobileSignIn from '@/modules/authentication/views/HBMobileSignIn/HBMobileSignIn.vue';
import { redirectToOnboardingSignIn } from '@/modules/common/routes/navigation-guards/redirectToOnboardingSignIn';

export const authenticationRoutes: RouteConfig[] = [
  {
    name: 'signin',
    path: '/signin',
    beforeEnter: redirectToOnboardingSignIn,
    alias: '/login',
    component: SignInView,
    meta: {
      requiresAuth: false,
      context: 'authentication'
    }
  },
  {
    name: 'signout',
    path: '/signout',
    component: SignOutView,
    meta: {
      context: 'authentication'
    }
  },
  {
    name: 'reset',
    path: '/reset',
    component: ResetView,
    meta: {
      requiresAuth: false,
      context: 'authentication'
    }
  },
  {
    name: 'passwordReset',
    path: '/reset-password/:token',
    component: PasswordResetView,
    meta: {
      requiresAuth: false,
      context: 'authentication'
    }
  },
  {
    name: 'security',
    path: '/security',
    component: SecurityView,
    meta: {
      requiresAuth: false,
      context: 'authentication'
    }
  },
  {
    name: 'verifiedEmail',
    path: '/verify-email/:hash',
    component: EmailVerifiedView,
    meta: {
      requiresAuth: false,
      context: 'account'
    }
  },
  {
    name: 'passwordSetup',
    path: '/setup/:token',
    component: PasswordSetupView,
    meta: {
      requiresAuth: false,
      context: 'account'
    }
  },
  {
    name: 'hbMobileSignIn',
    path: '/hb-mobile-sign-in',
    component: HBMobileSignIn,
    meta: {
      requiresAuth: false,
      context: 'authentication'
    }
  }
];
